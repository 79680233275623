import * as React from 'react'
import styled from 'styled-components'
import * as t from '../types'
import useProducts from './hooks/useProducts'
import { MdClose } from 'react-icons/md'
import ty from 'utils/typographie'
import ProductWidget from 'theme/molecules/ProductWidget'
import { ms } from 'modules/browser/const'
import Link from 'theme/atoms/Link'
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi'
import useCssCloseAnimation from 'hooks/useCssCloseAnimation'

type Props = {
  entry: t.Entry
  onClose: () => void
}

export default function Popup(props: Props) {
  const products = useProducts(props.entry.product_ids)
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const productsRef = React.useRef<HTMLDivElement | null>(null)
  const [isClosing, handleClose] = useCssCloseAnimation(500, props.onClose)

  React.useEffect(() => {
    if (!productsRef.current) return
    productsRef.current.scroll({
      left: scrollPosition * (180 + 15),
      top: 0,
      behavior: 'smooth',
    })
  }, [scrollPosition, products.data.length])

  return (
    <Wrapper className="Modal" isClosing={isClosing}>
      <div className="overlay" onClick={handleClose} />
      <div className="content">
        <div className="close-wrapper" onClick={handleClose}>
          <MdClose />
        </div>
        <h3 className="title">Shop the Look</h3>
        <div className="center">
          <div className="img-wrapper">
            <img alt="main" src={props.entry.media[0].cropped_url} />
            <Link className="user" to={props.entry.user_profile}>
              {props.entry.user_name}
            </Link>
          </div>
          <div className="products-wrapper">
            <div className="products" ref={productsRef}>
              {products.data.map((p, i) => (
                <ProductWidget
                  key={p.objectID}
                  product={p}
                  listName={`squarelovin-${props.entry.id}`}
                  index={i}
                />
              ))}
            </div>
            {scrollPosition !== 0 && (
              <div className="scroll prev" onClick={() => setScrollPosition((p) => p - 1)}>
                <FiChevronLeft />
              </div>
            )}
            {products.data.length > 3 && scrollPosition < products.data.length - 3 && (
              <div className="scroll next" onClick={() => setScrollPosition((p) => p + 1)}>
                <FiChevronRight />
              </div>
            )}
          </div>
        </div>
        <button onClick={handleClose}>Schließen</button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  animation-duration: 0.5s;
  animation-name: slidein;
  @keyframes slidein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  ${(p) =>
    p.isClosing &&
    `
    animation-duration: 0.5s;
    animation-name: slideout;
    @keyframes slideout {
      from { opacity: 1; }
      to { opacity: 0; }
    }
  `}
  > .overlay {
    opacity: 0.95;
    background-color: #f8f7f8;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999999999999999999999999999999999999998;
  }
  > .content {
    z-index: 99999999999999999999;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @media (min-width: ${ms.LAPTOP_L}px) {
      margin: 0 auto;
      max-width: ${ms.LAPTOP_L}px;
    }
    > .close-wrapper {
      position: absolute;
      right: 15px;
      top: 23px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      > svg {
        font-size: 18px;
        color: black;
      }
      @media (min-width: ${ms.TABLET}px) {
        top: 70px;
      }
      @media (min-width: ${ms.LAPTOP_L}px) {
        top: 130px;
      }
    }

    > .title {
      margin: 20px 15px;
      color: #363736;
      ${ty([28, 0.5, 36], 'Cormorant Garamond', '500')}
      text-align: center;

      @media (min-width: ${ms.TABLET}px) {
        margin: 60px 0 40px 0;
        ${ty([36, 0.7, 48])}
      }
      @media (min-width: ${ms.LAPTOP_L}px) {
        margin: 120px 0 60px 0;
      }
    }

    > .center {
      > .img-wrapper {
        width: 100%;
        position: relative;
        overflow: hidden;
        > img {
          width: 100%;
          margin-bottom: -7px;
        }

        > .user {
          position: absolute;
          right: 0;
          bottom: 0;
          padding: 5px 10px;
          background: rgb(248, 247, 248, 0.7);
          ${ty([11, 2.4, 25], 'Raleway')}
          color: #262626;
        }

        @media (min-width: ${ms.TABLET}px) {
          margin: 0 auto;
          margin-bottom: 40px;
          max-width: ${ms.TABLET - 40}px;
        }
      }

      > .products-wrapper {
        background: white;
        > .next {
          display: none;
        }
        > .products {
          padding: 20px 15px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 15px;
          min-height: 250px;

          @media (min-width: ${ms.TABLET}px) {
            grid-template-columns: 1fr 1fr 1fr;
            margin: 0 20px;
          }
        }
      }
      @media (min-width: ${ms.LAPTOP_L}px) {
        display: flex;
        max-width: 100%;

        > .img-wrapper {
          flex: 2;
          margin: 0;
        }
        > .products-wrapper {
          flex: 3;
          position: relative;
          width: 100%;
          height: 390px;
          overflow: hidden;
          scroll-behavior: smooth;
          padding-right: 15px;
          > .products {
            overflow: hidden;
            width: 100%;
            margin: 0;
            display: flex;
            > .ProductWidget {
              width: 180px;
              min-width: 180px;
            }
          }
          > .scroll {
            height: 80px;
            width: 20px;
            background: #555555;
            opacity: 0.6;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 110px;
            cursor: pointer;
            > svg {
              font-size: 18px;
              color: white;
            }
            &.prev {
              left: 15px;
            }
            &.next {
              right: 15px;
            }
          }
        }
      }
    }

    > button {
      color: #ffffff;
      font-family: Raleway;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 2.36px;
      line-height: 25px;
      background: #555555;
      text-transform: uppercase;
      margin: 30px 15px;
      padding: 8px;
      border: none;
      display: block;
      cursor: pointer;
      text-align: center;
      width: calc(100% - 30px);
      @media (min-width: ${ms.TABLET}px) {
        max-width: 320px;
        margin: 40px auto;
      }
      @media (min-width: ${ms.LAPTOP_L}px) {
        max-width: 320px;
        margin: 60px auto;
      }
    }
  }
`
